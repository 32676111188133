html,
body,
#root {
  height: 100vh;
}

.leaflet-container {
  width: 100%;
  height: 100%;
}

.bearing-arrow-container {
  display: inline-block;
  position: relative;
  top: 4px;
  left: 5px;
}

.bearing-arrow {
  display: inline-block;
  width: 20px;
  height: 20px;
}
